.iq__form {
  padding: 24px 0;
}

.iq__form h3 span {
  color: #8bc34a;
}

.iq__form__subtitle {
  margin-bottom: 32px;
}

.iq__form form {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}

.iq__form form .MuiTypography-body1 {
  margin-bottom: 24px;
}

.iq__form form .MuiInput-root {
  width: 270px;
  margin-bottom: 8px;
}

.iq__form__error {
    color: red;
    font-size: 12px;
    margin-bottom: 16px;
  }

.iq__form form button {
  width: 116px;
  margin-top: 16px;
}

@media (min-width: 960px) {
  .iq__form {
    padding: 32px;
    display: flex;
    justify-content: space-between;
  }
  .iq__form__text {
    max-width: 50%;
  }
  .iq__form__text h3 {
    font-size: 32px;
    margin-bottom: 16px;
  }
  .iq__form__subtitle {
    font-size: 18px;
  }
  .iq__form form {
    width: 30%;
    align-items: flex-start;
  }
  .iq__form form .MuiTypography-body1 {
    font-size: 18px;
    margin-bottom: 32px;
  }
}

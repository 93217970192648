.iq__enterprises{
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 72px;
}

.iq__enterprises__init{
    display: flex;
    flex-direction: column;
}

.iq__enterprises__init p{
    text-align: center;
    margin-top: 24px;
}

.iq__enterprises__init__img{
    height: 193.47px;
    width: 290px;
    margin-top: 24px;
    align-self: center;
}

.iq__enterprises__init__button{
    width: 161px;
    margin: 24px 0;
    align-self: center;
}

.iq__enterprises__init__call{
    display: flex;
    align-self: center;
    align-items: center;
    margin-bottom: 24px;
    text-decoration: none;
}

.iq__enterprises__governmental{
    padding: 24px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.iq__enterprises__governmental__content{
    display: flex;
    max-width: fit-content;
    margin-bottom: 16px;
    flex-direction: column;
}

.iq__enterprises__governmental__black{
    color: #FFFFFF;
    padding: 26px;
    max-width: 303px;
    box-sizing: border-box;
    border-radius: 24px;
    margin-bottom: 16px;
    background-color: #263238;
}

.iq__enterprises__governmental__black h2{
    color: #FFFFFF;
}

.iq__enterprises__governmental__black h3{
    color: #FFFFFF;
}

.iq__enterprises__governmental__black div{
    margin: 20px 0;
}

.iq__enterprises__governmental__black div h3{
    font-family: 'Ubuntu Light', sans-serif;
}

.iq__enterprises__governmental__black div h3:first-child{
    color: #8BC34A;
    font-family: 'Ubuntu Bold', sans-serif;
}

.iq__enterprises__governmental__green{
    color: #FFFFFF;
    width: 303px;
    height: 221px;
    display: flex;
    align-items: center;
    border-radius: 24px;
    justify-content: center;
    background-color:  #8BC34A;
}

.iq__enterprises__governmental__green img{
    height: 119.53px;
    width: 120px;
}

.iq__enterprises__governmental__image{
    margin-bottom: 16px;
}

.iq__enterprises__advantages{
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    flex-direction: column;
}

.iq__enterprises__advantages h3{
    margin-bottom: 16px;
}

.iq__enterprises__advantages .MuiTypography-subtitle1{
    font-family: 'Ubuntu Bold';
    margin-bottom: 8px;
}

.iq__enterprises__advantages ul{
    margin-bottom: 16px;
}

.iq__enterprises__advantages img{
    width: 295px;
    height: 288.06px;
}

.iq__enterprises__benefits{
    margin: 0 -16px;
    padding: 24px 16px;
    background-color: rgba(236,239,241,0.5);
}

.iq__enterprises__benefits__content{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.iq__enterprises__benefits ul{
    margin: 16px 0;
    align-self: flex-start;
}

.iq__enterprises__benefits li{
    margin-bottom: 12px;
}

.iq__enterprises__collaborators{
    margin: 0 -16px;
    padding: 24px 16px;
}

.iq__enterprises__collaborators h3{
    font-size: 32px;
    line-height: 37px;
    padding: 0 32px;
}

.iq__enterprises__collaborators__container{
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
}

.iq__enterprises__collaborators__card{
    width: 270px;
    height: 270px;
    margin-top: 20px;
    margin: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.iq__enterprises__collaborators__card img{
    width: 100%;
    height: 100%;
}

@media (min-width: 960px) {
    .iq__enterprises{
        padding-top: 142px;
    }
    .iq__enterprises__init{
        padding: 0 32px;
        margin-bottom: 32px;
    }
    .iq__enterprises__init p{
        text-align: start;
        margin-top: 8px;
        order: 2;
    }
    .iq__enterprises__init h2{
        order: 0;
        max-width: 432px;
        font-size: 48px;
    }
    .iq__enterprises__init h6{
        order: 1;
        max-width: 432px;
        font-size: 24px;
        line-height: 28px;
    }
    .iq__enterprises__init__img{
        order: 4;
        align-self: flex-end;
        position: absolute;
        height: 300.22px;
        width: 450px;
        margin: 0;
    }
    .iq__enterprises__init__button{
        order: 3;
        width: 218px;
        margin: 24px 0 16px 0;
        align-self: flex-start;
    }
    .iq__enterprises__init__call{
        order: 3;
        align-self: flex-start;
        margin-left: 32px;
        text-decoration: none;
    }
    .iq__enterprises__init__call p{
        font-size: 18px;
        order: 1;
    }
    .iq__enterprises__init__call svg{
        order: 2;
        margin: 5px;
    }
    .iq__enterprises__governmental{
        padding: 20px 0;
        flex-direction: row;
        justify-content: space-between;
    }
    .iq__enterprises__advantages{
        flex-direction: row-reverse;
    }
    .iq__enterprises__advantages div{
        max-width: 50%;
        margin-left: 86px;
    }
    .iq__enterprises__advantages div h3{
        font-size: 48px;
        margin-bottom: 24px;
    }
    .iq__enterprises__advantages .MuiTypography-subtitle1{
        font-size: 24px;
    }
    .iq__enterprises__advantages .MuiTypography-subtitle2{
        font-size: 18px;
    }
    .iq__enterprises__benefits{
        margin: 0 -40px;
        padding: 32px 72px;
    }
    .iq__enterprises__benefits h3{
        font-size: 48px;
        margin-bottom: 16px;
    }
    .iq__enterprises__benefits h6{
        font-size: 20px;
        margin-bottom: 35px;
    }
    .iq__enterprises__benefits__content{
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
    }
    .iq__enterprises__benefits__content p{
        font-size: 18px;
    }
    .iq__enterprises__benefits__content img{
        height: 224px;
        width: 319px;
    }

    .iq__enterprises__collaborators{
        padding: 32px 40px;
        margin: 0 -40px;
    }

    .iq__enterprises__collaborators h3{
        font-size: 48px;
        line-height: 37px;
        margin: 0 auto;
        text-align: center;
    }

    .iq__enterprises__collaborators__container{
        padding: 12px 32px 8px 32px;
        flex-direction: row;
        justify-content: center;
    }

    .iq__enterprises__collaborators__card{
        height: 200px;
        width: 200px;
    }

    .iq__enterprises__collaborators__card img{
        width: 100%;
        height: 100%;
    }
}
.iq__why{
    background-color: rgba(236,239,241,0.5);
    padding: 24px 16px;
    margin: 0 -16px;
}

.iq__why h3{
    font-family: 'Ubuntu Regular', sans-serif;
    font-weight: normal;
}

.iq__why h3 span{
    font-family: 'Ubuntu Bold', sans-serif;
}

.iq__why__reasons{
    padding: 32px 32px 8px 32px;
    margin-top: 24px;
    background-color: white;
}

.iq__why__reasons h2{
    display: none;
}

.iq__why__reason{
    margin-bottom: 24px;
}

.iq__why__reason img{
    height: 24px;
    width: 24px;
    margin-bottom: 8px;
}

.iq__why__reason h6{
    font-family: 'Ubuntu Bold', sans-serif;
    font-weight: bold;
}


@media (min-width: 960px) {
    .iq__why{
        margin: 0 -40px;
    }
    .iq__why h3{
        margin: 0 72px;
    }
    .iq__why__reasons{
        margin: 28px 40px;
        padding: 28px 32px;
    }
    .iq__why__reasons h2{
        display: block;
        margin-top: 8px;
        margin-bottom: 32px;
    }
    .iq__why__reasons__container{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .iq__why__reason{
        width: 236px;
    }
}